import { BASE_URL } from "../configs";
import { axiosInstanceCollab, axiosInstanceVisit } from "./config";

export const getAllCandidatMkUntreated = () => {
  return axiosInstanceCollab.get(`${BASE_URL}/candidatMkUntreated`);
};

export const getAllCandidatOffreUntreated = () => {
  return axiosInstanceCollab.get(`${BASE_URL}/candidatOffreUntreated`);
};

export const candidatServiceUntreated = () => {
  return axiosInstanceCollab.get(`${BASE_URL}/candidatServiceUntreated`);
};

export const getAllCommentUnread = () => {
  return axiosInstanceCollab.get(`${BASE_URL}/commentUnread`);
};

export const getAllResponseUnread = () => {
  return axiosInstanceVisit.get(`${BASE_URL}/reponseUnread`);
};
