<template>
  <li class="dropdown text-end" v-if="isLoggedIn" v-click-outside="close">
    <button
      class="btn btn-primary rounded-circle position-relative btn-notif d-flex align-items-center justify-content-center"
      type="button"
      id="dropdownMenuButton1"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      @click="toggleShow()"
    >
      <IcoNotif class="position-absolute" />
      <span
        v-if="reponseUnread.length"
        class="position-absolute top-0 start-100 translate-middle border border-dark border-2 badge bg-warning rounded-pill"
      >
        {{ reponseUnread.length }}
      </span>
    </button>
    <div
      v-if="show"
      class="notif dropdown-menu-end dropdown-w border-0 shadow p-0"
      aria-labelledby="dropdownMenuButton1"
    >
      <nav class="nav nav-pills flex-lg-row flex-column text-end bg-primary">
        <div
          class="flex-sm-fill text-sm-center tab-link py-3 fw-bold position-relative"
        >
          Notification
        </div>
      </nav>
      <div>
        <a
          class="text-start list-group-item border-0 list-notif border-top"
          aria-current="true"
          v-for="(reponse, i) in reponseUnread"
          :key="i"
          @click="navigateToArticle(reponse.id_article)"
        >
          <div class="d-flex w-100 justify-content-between">
            <h6 class="mb-1 text-primary">
              {{ reponse.fname_collab }}
              a repondu à votre commentaire "{{ reponse.content_comment }}"
            </h6>
            <small class="date-notification">{{
              moment(new Date(reponse.date_rep), "YYYYMMDD").fromNow(true)
            }}</small>
          </div>
          <small class="mb-1">{{ reponse.content_rep }}</small>
          <br />
          <!-- <small class="text-muted">And some small print.</small> -->
        </a>
        <div
          v-if="!reponseUnread.length"
          class="text-center list-group-item border-0 list-notif text-white"
        >
          Aucune notification
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import IcoNotif from "vue-material-design-icons/Bell.vue";
import { getAllResponseUnread } from "../../api/notification";
import { decodeToken } from "../../utils/decodeToken";
import { socket } from "../../api/socket";

export default {
  name: "NotificationVisit",
  components: { IcoNotif },
  computed: {
    isLoggedIn() {
      return this.$store.getters["visiteurStore/isLoggedIn"];
    },
    me() {
      return this.$store.getters["visiteurStore/me"];
    },
  },
  data() {
    return {
      scrollPosition: null,
      numberTab: 1, //index pour le tab
      show: false,
      reponseUnread: [],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
    try {
      const decodeV = decodeToken(localStorage.getItem("token"));
      if (decodeV) {
        this.fetch();
      }
    } catch (err) {
      console.log();
    }
    socket.on("arrival-view", () => {
      this.fetch();
    });
    socket.on("arrival-comment", () => {
      this.fetch();
    });
  },
  methods: {
    close() {
      this.show = false;
    },
    fetch() {
      getAllResponseUnread().then((results) => {
        this.reponseUnread = results.data;
      });
    },
    toggleShow() {
      this.show = !this.show;
    },
    navigateToArticle(param) {
      this.$router.push(`/select-article/${param}`).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";

#navBarMK-CO a {
  color: $light;
  transition: 250ms;

  &:hover {
    color: darken($primary, $amount: 5);
  }

  &.router-link-exact-active {
    color: $primary;
  }
}

.btn-user {
  transition: 250ms;

  img {
    transition: 500ms;
  }

  &:hover {
    color: $primary;

    img {
      transform: scale(1.2);
    }
  }
}

// STYLE NOTIF DEBUT

.dropdown-w {
  min-width: 30rem;
}

.tab-link {
  transition: 250ms;

  &:hover {
    background-color: lighten($secondary, $amount: 10);
    color: $primary;
    cursor: pointer;
  }
}

.list-notif {
  transition: 250ms;
  cursor: pointer;

  h6 {
    transition: 250ms;
  }

  &:nth-child(2n) {
    background-color: lighten($secondary, $amount: 5);
  }

  &:nth-child(2n + 1) {
    background-color: lighten($secondary, $amount: 10);
  }

  &:hover {
    background-color: lighten($primary, $amount: 35);
    color: $dark !important;

    h6 {
      color: $dark !important;
    }
  }
}

.tapaho {
  color: $light !important;
}
.notif {
  position: absolute;
  right: 1rem;
  max-height: 90vh;
  overflow-y: auto;
}

.btn-notif {
  padding: 1.15rem;
}
</style>
