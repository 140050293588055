import { BASE_URL } from "../configs";
import { axiosInstanceCollab } from "./config";
import FormData from "form-data";

export const getAllServicesWithoutContent = () => {
  return axiosInstanceCollab.get(`${BASE_URL}/servicesWithoutContent`);
};

export const getAllServices = () => {
  return axiosInstanceCollab.get(`${BASE_URL}/services`);
};

export const updateServicesIcon = (id, form) => {
  let data = new FormData();
  if (form.image) {
    data.append("logo", form.image, "logo");
  }
  return axiosInstanceCollab.put(`${BASE_URL}/servicesIcon/${id}`, data);
};

export const updateServices = (id, data) => {
  return axiosInstanceCollab.put(`${BASE_URL}/services/${id}`, data);
};
